import {translate} from "./i18n.js";

export default class CmsHelper {
    static getPageIcon(page) {
        const pageDetails = CmsHelper.getPageDetails(page);
        return pageDetails ? pageDetails.icon : `window`
    }

    static getPageDetails(page) {
        if (page.homePage === true) {
            return {
                value: `home`,
                description: translate(`Home Page|Page d'accueil`),
                variant: `primary`,
                icon: getPublicIcon(`window-home`),
                glyph: `fa-house`
            };
        }
        switch (page.type) {
            case `page`:
            case `blocks`:
                return {
                    value: `blocks`,
                    description: translate(`Editable|Éditable`),
                    variant: `light`,
                    icon: getPublicIcon(`window-editable`),
                    glyph: `fa-pen`
                };
            case `map`:
                return {
                    value: `map`,
                    description: translate(`Map|Plan`),
                    variant: `light`,
                    icon: getPublicIcon(`window-map`),
                    glyph: `fa-map-location-dot`
                };
            case `cms-item-view`:
                return {
                    value: `cms-item-view`,
                    description: translate(`Item Details|Détails d'un élément`),
                    variant: `light`,
                    icon: getPublicIcon(`window-details`),
                    glyph: `fa-memo-list`
                };
            case `cms-item-list`:
                return {
                    value: `cms-item-list`,
                    description: translate(`Item List|Liste d'éléments`),
                    variant: `light`,
                    icon: getPublicIcon(`window-list`),
                    glyph: `fa-square-list`
                };
            default:
                return {
                    value: `template`,
                    description: translate(`Template|Gabarit`),
                    variant: `warning`,
                    icon: getPublicIcon(`website`),
                    glyph: `fa-lock`
                };
        }
    }

    static textToPermalink(text, separator = `-`) {
        text = text.toString().toLowerCase().trim();

        const sets = [
            {to: `a`, from: `[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]`},
            {to: `ae`, from: `[Ä]`},
            {to: `c`, from: `[ÇĆĈČ]`},
            {to: `d`, from: `[ÐĎĐÞ]`},
            {to: `e`, from: `[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]`},
            {to: `g`, from: `[ĜĞĢǴ]`},
            {to: `h`, from: `[ĤḦ]`},
            {to: `i`, from: `[ÌÍÎÏĨĪĮİỈỊ]`},
            {to: `j`, from: `[Ĵ]`},
            {to: `ij`, from: `[Ĳ]`},
            {to: `k`, from: `[Ķ]`},
            {to: `l`, from: `[ĹĻĽŁ]`},
            {to: `m`, from: `[Ḿ]`},
            {to: `n`, from: `[ÑŃŅŇ]`},
            {to: `o`, from: `[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]`},
            {to: `oe`, from: `[ŒÖ]`},
            {to: `p`, from: `[ṕ]`},
            {to: `r`, from: `[ŔŖŘ]`},
            {to: `s`, from: `[ŚŜŞŠ]`},
            {to: `ss`, from: `[ß]`},
            {to: `t`, from: `[ŢŤ]`},
            {to: `u`, from: `[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]`},
            {to: `ue`, from: `[Ü]`},
            {to: `w`, from: `[ẂŴẀẄ]`},
            {to: `x`, from: `[ẍ]`},
            {to: `y`, from: `[ÝŶŸỲỴỶỸ]`},
            {to: `z`, from: `[ŹŻŽ]`},
            {to: `-`, from: `[·/_,:;']`}
        ];

        sets.forEach((set) => {
            text = text.replace(new RegExp(set.from, `gi`), set.to);
        })

        text = text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, `-`) // Replace spaces with -
            .replace(/&/g, `-and-`) // Replace & with 'and'
            .replace(/[^\w-]+/g, ``) // Remove all non-word chars
            .replace(/--+/g, `-`) // Replace multiple - with single -
            .replace(/^-+/, ``) // Trim - from start of text
            .replace(/-+$/, ``); // Trim - from end of text

        if (typeof separator !== `undefined` && separator !== `-`) {
            text = text.replace(/-/g, separator);
        }

        return text;
    }
}
