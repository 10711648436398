<template>
    <div class="input-glyph form-control">
        <template v-if="!currentGlyph">
            <b-btn variant="light-primary" @click="show()">
                <i class="fas fa-edit mr-1"/>
                {{placeholder|tr}}
            </b-btn>
        </template>

        <template v-else>
            <div class="d-flex">
                <b-btn variant="light" class="px-2 py-1" @click="show()">
                    <i class="fa-fw" :class="[fontAwesomePrefix, currentGlyph]" style="font-size: 24px;"/>
                </b-btn>

                <div class="overview ml-2">
                    <span v-if="title">{{title|tr}}</span>
                </div>

                <b-btn variant="light" @click="clear()" class="ml-auto text-danger" :title="'Remove Icon|Enlever l\'icône'|tr" v-b-tooltip>
                    <i class="fas fa-trash"/>
                </b-btn>
            </div>
        </template>

        <e-font-awesome-picker v-if="pickerActive" v-model="glyph" @apply="apply" :prefix="fontAwesomePrefix" :visible.sync="pickerVisible"/>
    </div>
</template>

<script>
import EFontAwesomePicker from "../../../../../vue-components/components/e-font-awesome-picker.vue";

// input-glyph #hot-reload-debug
export default {
    name: `input-glyph`,
    components: {EFontAwesomePicker},
    props: {
        value: {},
        title: {type: String},
        thumbnail: {type: String},
        placeholder: {type: String, default: `Select Icon|Sélectionner`},
        showEdit: {type: Boolean},
        allowEmpty: {type: Boolean}
    },
    data() {
        return {
            pickerActive: false,
            pickerVisible: false,
            glyph: null,
        }
    },
    computed: {
        currentGlyph: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    mounted() {
        this.reset();
    },
    methods: {
        apply() {
            this.currentGlyph = this.glyph;
            this.pickerVisible = false;
        },
        clear() {
            this.currentGlyph = null;
            this.pickerVisible = false;
        },
        show() {
            this.pickerActive = true;
            this.pickerVisible = true;
        },
        reset() {
            this.glyph = this.currentGlyph;
            this.pickerVisible = false;
        }
    },
    watch: {
        currentGlyph() {
            this.reset();
        }
    }
}
</script>

<style lang="scss" scoped>
.input-glyph {
    padding: 4px;
    height: 52px;

    .d-flex {
        height: 100%;
        align-items: center;

        .overview {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            height: 100%;
            overflow: hidden;
            font-weight: 500;
        }
    }
}
</style>
