<template>
    <b-modal v-model="visibleComputed" @hide="$emit(`close`, $event)" scrollable size="xl" modal-class="e-font-awesome-picker" body-bg-variant="white">
        <template #modal-title>
            <span v-tr>Font Awesome Library|Bibliothèque Font Awesome</span>
            <e-search-input class="ml-auto" v-model="search"/>
        </template>
        <div class="library-container">
            <div class="sidebar">
                <b-btn-group vertical>
                    <b-btn v-if="search" variant="primary">
                        <span v-tr>Search Result|Résultats de la recherche</span>
                    </b-btn>
                    <b-btn :variant="categorySelected === key && !search ? 'primary' : 'light'" v-for="(category, key) in Categories" :key="`category-${key}`" @click="selectCategory(key)">
                        {{category.label}}
                    </b-btn>
                </b-btn-group>
            </div>
            <div class="library">
                <div class="icon" v-for="icon in categoryIcons" :key="icon" @click="selectIcon(`fa-${icon}`)" :class="{ 'selected': `fa-${icon}` === currentGlyph }">
                    <i :class="[prefix, `fa-${icon}`]"/>
                </div>
            </div>
        </div>

        <template #modal-footer="{hide}">
            <b-btn @click="hide">
                <span v-tr>Cancel|Annuler</span>
            </b-btn>
            <b-btn variant="primary" @click="apply">
                <span v-tr>Select|Sélectionner</span>
            </b-btn>
        </template>
    </b-modal>
</template>

<script>
    import Categories from "@fortawesome/fontawesome-pro/metadata/categories.yml";
    import Icons from "@fortawesome/fontawesome-pro/metadata/icons.yml";
    import ESearchInput from "./e-search-input.vue";

    export default {
        name: `e-font-awesome-picker`,
        components: {ESearchInput},
        props: {
            value: {},
            visible: {type: Boolean},
            prefix: {type: String, default: `fas`},
            type: {type: String, default: `image`}
        },
        data() {
            return {
                tabIndex: 0,
                search: ``,
                categorySelected: `shapes`,
                Categories
            }
        },
        computed: {
            visibleComputed: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit(`update:visible`, value);
                }
            },
            currentGlyph: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit(`input`, value);
                }
            },
            categoryIcons() {
                if (this.search) {
                    let icons = [];
                    const searchRegexp = new RegExp(this.search, `gi`);
                    for (let key in Icons) {
                        const icon = Icons[key];
                        for (let term of icon.search.terms) {
                            if (searchRegexp.test(term)) {
                                icons.push(key);
                                break;
                            }
                        }
                    }
                    return icons;
                }
                if (!this.categorySelected) {
                    return [];
                }
                return Categories[this.categorySelected].icons;
            }
        },
        methods: {
            selectIcon(icon) {
                this.currentGlyph = icon;
            },
            selectCategory(category) {
                this.categorySelected = category;
                this.search = ``;
            },
            show() {
                this.visibleComputed = true;
                this.$emit(`show`);
            },
            close() {
                this.visibleComputed = false;
                this.$emit(`close`);
            },
            apply() {
                this.$emit(`apply`, this.currentGlyph);
                this.close();
            }
        }
    }
</script>

<style lang="scss">
.e-font-awesome-picker {
    .modal-title {
        display: flex;
        flex: 1;
        padding-right: 5px;
    }
}
</style>

<style lang="scss" scoped>
.library-container {
    display: flex;
    width: 100%;
    height: 100%;

    .sidebar {
        overflow: auto;
        flex: 1;
        min-width: 200px;
        max-width: 200px;
    }

    .library {
        flex: 20;
        overflow: auto;
        padding: 5px;

        .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #2c3e50;
            width: 64px;
            height: 64px;
            font-size: 30px;
            object-fit: contain;
            cursor: pointer;
            border-radius: 5px;

            &.selected,
            &:hover {
                outline: 1px solid #005FE5FF !important;
                background-color: rgba(0, 95, 229, 0.2);
            }
        }
    }
}
</style>
